$(document).ready(function () {
    // Get the page lang
    let pageLang = $('html').attr('lang');
    let isRtl = false;

    if (pageLang == 'ar') {
        isRtl = true;
    }

    // Start Toastr
    // Toastr
toastr.options = {
    "closeButton": true,
    "debug": false,
    "newestOnTop": false,
    "progressBar": true,
    "positionClass": "toast-top-center",
    "onclick": null,
    "showDuration": "300",
    "hideDuration": "1000",
    "timeOut": "5000",
    "extendedTimeOut": "1000",
    "showEasing": "swing",
    "hideEasing": "linear",
    "showMethod": "fadeIn",
    "hideMethod": "fadeOut"
}
    // End Toastr

    /* Navbar */
    // Setup offcanvas mobile navbar
    let navToggler = $('.navbar-toggler');
    let navCollapse = $('.navbar-collapse');
    let closeNavbar = $('.close-navbar');

    // Prevent closing from click inside dropdown
    $(document).on('click', '.dropdown-menu', function (e) {
        e.stopPropagation();
    })

    // Close offcanvas navbar when click anywhere outside of the navbar collapsed
    $(document).on('click', function (event) {
        if (
            !$(event.target).closest('.navbar-toggler').length &&
            !$(event.target).closest('.navbar-collapse').length
        ) {
            $('.navbar-collapse').removeClass('show')
        }
    });

    // When click on burger icon add class show
    navToggler.on('click', function () {
        navCollapse.toggleClass('show')
    });

    // When click on close button within the offcanvas navbar remove class show and hide the navbar
    closeNavbar.on('click', function () {
        navCollapse.removeClass('show')
    });
    /* /Navbar */

    /* Sticky Navbar */
    let navbar = $('.navbar');

    function checkScrollValue() {
        if ($(window).scrollTop() > 200) {
            navbar.addClass('sticky-navbar');
        } else {
            navbar.removeClass('sticky-navbar');
        }
    };

    $(window).on('scroll', function () {
        checkScrollValue()
    });
    /* /Sticky Navbar */

    /* Smooth Scroll */
    let menus = $('.nav-link-item');
    menus.on('click', function (e) {
        e.preventDefault();
        var navigateTo = $(this).attr('href');
        var elementPosition = $(navigateTo).offset().top;
        $(window).scrollTop(elementPosition - 130);
    });
    /* /Smooth Scroll */

    /* Home Carousel */
    let homeCarousel = $('.home-carousel');

    homeCarousel.on('initialize.owl.carousel', function () {
        $('.home-carousel').find('.item:first-of-type h2').addClass('animate__fadeInLeft').css('visibility', 'visible');
    });

    if (homeCarousel.length > 0) {
        homeCarousel.owlCarousel({
            items: 1,
            loop: true,
            dots: false,
            rtl: isRtl,
            navContainer: '.home-carousel-navs',
            navText: ['<i class="fas fa-chevron-left"></i>', '<i class="fas fa-chevron-right"></i>'],
        });
    }

    homeCarousel.on('translate.owl.carousel', function (event) {
        var currentItem = event.item.index;
        var allItems = $('.home-carousel').find('h2');
        var nextItem = $('.home-carousel .item').eq(currentItem).find('h2');

        allItems.removeClass('animate__fadeInLeft').css('visibility', 'hidden');
        nextItem.addClass('animate__fadeInLeft').css('visibility', 'visible');
    });
    /* /Home Carousel */

    /* Partners Carousel */
    // let partnersCarousel = $('.partners-carousel');
    // if (partnersCarousel.length > 0) {
    //     partnersCarousel.owlCarousel({
    //         loop: true,
    //         dotsContainer: '.partners-carousel-dots',
    //         dotsEach: 2,
    //         rtl: isRtl,
    //         responsive: {
    //             0: {
    //                 items: 1,
    //                 stagePadding: 30,
    //             },

    //             375: {
    //                 items: 2,
    //             },

    //             992: {
    //                 items: 3,
    //                 nav: true,
    //                 navContainer: '.partners-carousel-navs',
    //                 navText: ['<i class="fas fa-chevron-left"></i>', '<i class="fas fa-chevron-right"></i>'],
    //             }
    //         }
    //     });
    // }
    /* /Partners Carousel */

    /* Start Back to top script */
    // Make the back to top visible
    let currentTopScroll = $(window).scrollTop();
    let screenWidth = window.innerWidth;
    let scrollTopBtn = $(".back-to-top");

    function controlBackToTop() {
        'use strict';
        currentTopScroll = $(window).scrollTop();
        if (screenWidth > 1600) {
            if (currentTopScroll > 750) {
                scrollTopBtn.addClass('activeScroll animate__bounceIn');
            } else {
                scrollTopBtn.removeClass('activeScroll animate__bounceIn');
            }
        } else if (screenWidth > 1199) {
            if (currentTopScroll > 550) {
                scrollTopBtn.addClass('activeScroll animate__bounceIn');
            } else {
                scrollTopBtn.removeClass('activeScroll animate__bounceIn');
            }
        } else if (screenWidth > 991) {
            if (currentTopScroll > 450) {
                scrollTopBtn.addClass('activeScroll animate__bounceIn');
            } else {
                scrollTopBtn.removeClass('activeScroll animate__bounceIn');
            }
        } else {
            if (currentTopScroll > 350) {
                scrollTopBtn.addClass('activeScroll animate__bounceIn');
            } else {
                scrollTopBtn.removeClass('activeScroll animate__bounceIn');
            }
        }
    }

    $(window).on('scroll', function () {
        controlBackToTop();
    });

    scrollTopBtn.on("click", function (el) {
        el.preventDefault();
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    });
    /* End Back to top script */
});